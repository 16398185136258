import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//datepicker
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

//ckeditor
import {CKEditor} from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';

//utils
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import {
	create,
	getAll,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';
import {
	separateRoles,
	cleanUsers,
	uniqueUsers,
	buildInvestigatorProject
} from '../../../../utils/auxiliar/manageProject';

//middleware
import moment from 'moment';
import produce from 'immer';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { validate } from './validations';
import { ROUTES } from '../../../../utils/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { useSelector } from 'react-redux';

registerLocale('es', es);

export const ManageProject = () => {
	let { investigatorId, isAdmin, userRoleId } = useSelector(({ user }) => user);

	const { PROJECTS, INVESTIGATORS, investigatorPROJECT, PROGRAMS } = paths;
	const { id } = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const [isCreate, setIsCreate] = useState(false);
	const [programs, setPrograms] = useState([]);

	//userForm
	const [nameEs, setNameEs] = useState('');
	const [nameEn, setNameEn] = useState('');
		const [nameVa, setNameVa] = useState('');
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [code, setCode] = useState('');
	const [funding, setFunding] = useState('');
	const [budget, setBudget] = useState('');
  const [institutebudget, setInstituteBudget] = useState("");
	const [ips, setIps] = useState([]);
	const [chosenIps, setChosenIps] = useState([]);
	const [participants, setParticipants] = useState([]);
	const [chosenParticipants, setChosenParticipants] = useState([]);
	const [externalIp, setExternalIp] = useState('');
	const [externalParticipants, setExternalParticipants] = useState('');
	const [descriptionEs, setDescriptionEs] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [descriptionVa, setDescriptionVa] = useState('');
	const [link, setLink] = useState('');
	const [convocationLink, setConvocationLink] = useState('')
	const [image, setImage] = useState();
	const [message, setMessage] = useState('');
	const [selected, setSelected] = useState('');
	const [isProjectPublic, setIsProjectPublic] = useState('')
	const [isDescriptionPublic, setIsDescriptionPublic] = useState('')
	const [isFundingPublic, setIsFundingPublic] = useState('')
	const [isBudgetPublic, setIsBudgetPublic] = useState('')
	const [chosenProgramId, setChosenProgramId] = useState("");


	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMounted = useRef(false);
	const source = Axios.CancelToken.source();

	useEffect(() => {
		isMounted.current = true;
		const { pathname } = history.location;
		pathname.includes('crear') ? loadDefaultData() : loadEditData();
		return () => {
			isMounted.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadDefaultData = async () => {
		try {
			let investigators = await getAll(INVESTIGATORS, source);
			let programs = await getAll(PROGRAMS, source);
			if (isMounted.current) {
				setIsCreate(true);
				setAllowedEdit(true);
				setTimeout(() => filterAdmins(investigators), 250);
				setPrograms(programs);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterAdmins = (investigators) => {
		let newinvestigators = investigators.filter(
			(u) =>
				u.email !== 'admin@admin.com' &&
				u.email !== 'sombradoble@sombradoble.es'
		);
		setIps(newinvestigators);
		setParticipants(newinvestigators);
	};

	const loadEditData = async () => {
		try {
			let response = await getById(PROJECTS, id, source);
			isMounted.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const buildObjToLoad = async (p) => {
		let allPrograms = await getAll(PROGRAMS, source);

		const startDateWithOffset = new Date(p.startDate);
		const startDateNoOffset = (new Date(startDateWithOffset.getTime() - startDateWithOffset.getTimezoneOffset() * -60000));
		const endDateWithOffset = new Date(p.endDate);
		const endDateNoOffset = (new Date(endDateWithOffset.getTime() -	endDateWithOffset.getTimezoneOffset() * -60000));

		setNameEn(p.nameEn);
		setNameEs(p.nameEs);
		setNameVa(p.nameVa)
		setDescriptionEn(p.descriptionEn);
		setDescriptionEs(p.descriptionEs);
		setDescriptionVa(p.descriptionVa)
		setBudget(p.budget);
    setInstituteBudget(p.institutebudget)
		setFunding(p.funding);
		setCode(p.code);
		setExternalIp(p.externalIp);
		setExternalParticipants(p.externalParticipants);
		setLink(p.link);
		setSelected(p.selected ? 1 : 0);
		setIsProjectPublic(p.isProjectPublic ? 1 : 0)
		setIsDescriptionPublic(p.isDescriptionPublic ? 1 : 0)
		setIsFundingPublic(p.isFundingPublic ? 1 : 0)
		setIsBudgetPublic(p.isBudgetPublic ? 1 : 0)
		setConvocationLink(p.convocationLink)
		setStartDate(startDateNoOffset);
		setEndDate(endDateNoOffset);
		setImage(p.image);
		setChosenProgramId(p.program?.id)
		setPrograms(allPrograms);

		let investigators = await getAll(INVESTIGATORS, source);
		if (isMounted.current) {
			setIpsDisplay(p, investigators);
			setParticipantsDisplay(p, investigators);
			setAllowedEdit(true);
		}
	};

	const setIpsDisplay = async ({ staffProjects }, investigators) => {
		const { ips, participants } = separateRoles(staffProjects);
		setChosenIps(ips);

		//get unassigned investigators by comparing with chosenIps
		let rawIps = uniqueUsers(investigators, ips);
		let unassignedIps = uniqueUsers(rawIps, participants);

		setIps(cleanUsers(unassignedIps));
	};

	const setParticipantsDisplay = async (
		{ staffProjects },
		investigators
	) => {
		const { ips, participants } = separateRoles(staffProjects);
		setChosenParticipants(participants);

		//get unassigned investigators by comparing with participants
		let rawInvs = uniqueUsers(investigators, participants);
		let unassignedInvs = uniqueUsers(rawInvs, ips);

		setParticipants(cleanUsers(unassignedInvs));
	};

	//----------------------------------------

	// HANDLE INVESTIGATORS
	const addInvestigator = (ip, investigator) => {
		ip = JSON.parse(ip);

		investigator === 'IP'
			? //add chosenIp
			setChosenIps((state) =>
				produce(state, (drafState) => {
					drafState.push(ip);
				})
			)
			: //add chosenParticipant
			setChosenParticipants((state) =>
				produce(state, (drafState) => {
					drafState.push(ip);
				})
			);

		//remove ip from list
		setIps((state) =>
			produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
		);

		//remove participant from list
		setParticipants((state) =>
			produce(state, (drafState) => drafState.filter(({ id }) => id !== ip.id))
		);
	};

	const removeInvestigator = (ip, investigator) => {
		if (!allowedEdit) return;
		investigator === 'IP'
			? // remove chosenIp
			setChosenIps((state) =>
				produce(state, (drafState) =>
					drafState.filter(({ id }) => id !== ip.id)
				)
			)
			: // remove chosenParticipan
			setChosenParticipants((state) =>
				produce(state, (drafState) =>
					drafState.filter(({ id }) => id !== ip.id)
				)
			);

		// add ip to list
		setIps((state) =>
			produce(state, (drafState) => {
				drafState.push(ip);
			})
		);

		// add participant to list
		setParticipants((state) =>
			produce(state, (drafState) => {
				drafState.push(ip);
			})
		);
	};

	const handleSubmit = () => {
		const project = build();
		const notValid = validate(project);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
		let question = isCreate ? 'crear un' : 'editar este';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} proyecto?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => isConfirmed && handleConfirmation(project));
	};

	const handleConfirmation = (project) => {
		let createOrUpdate = isCreate
			? create(PROJECTS, project, source)
			: update(PROJECTS, id, project, source);
		createOrUpdate
			.then(async ({ id: projectId }) => {
				const investigatorProjects = buildInvestigatorProject(
					chosenParticipants,
					chosenIps,
					projectId
				);
				await create(
					`${investigatorPROJECT}/${projectId}`,
					investigatorProjects,
					source
				);
				let createOrUpdateText = isCreate ? 'creado' : 'editado';

				Swal.fire({
					icon: 'success',
					text: `Proyecto ${createOrUpdateText} correctamente.`
				});
				if (typeof image !== 'string') {
					const formData = new FormData();
					formData.append('image', image);
					await saveFile(PROJECTS, projectId, 'image', formData, source);
				}
				history.push(`${ROUTES.Projects.all}/${projectId}`);
				isMounted.current && setIsCreate(false);
			})
			.catch(() => Swal.fire({ icon: 'error', text: SwalError }));
	};

	const build = () => {
		const project = {
			nameEs,
			nameEn,
			nameVa,
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
			descriptionEs,
			descriptionEn,
			descriptionVa,
			code,
			link,
			funding,
			budget,
      institutebudget,
			externalIp,
			externalParticipants,
			programId: +chosenProgramId,
			convocationLink
		};
		project.selected = selected === '1' || selected === 1 ? true : false;
		project.isProjectPublic = isProjectPublic === 1 || isProjectPublic === '1' ? true : false
		project.isDescriptionPublic = isDescriptionPublic === 1 || isDescriptionPublic === '1' ? true : false
		project.isFundingPublic = isFundingPublic === 1 || isFundingPublic === '1' ? true : false
		project.isBudgetPublic = isBudgetPublic === 1 || isBudgetPublic === '1' ? true : false
		return project;
	};

	const editDeleteButtons = () => {
		if (isCreate) {
			return (
				<button className="baseBtn" onClick={handleSubmit}>
					Crear Proyecto
				</button>
			);
		}
		//if is update && logged user included as IP

		return (
			<>
				<button className="baseBtn" onClick={handleSubmit}>
					Editar Proyecto
				</button>
				<button
					className="smallBtn btn-danger ml-1"
					onClick={() => deleteIt(PROJECTS, id, ROUTES.Projects.all, source)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			</>
		);
	};

	return (
    <div className="centerPage">
      {(isAdmin || userRoleId === 3 || userRoleId === 2) && (
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <div>
                <h3>Proyecto </h3>
              </div>
              <div>{editDeleteButtons()}</div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center text-danger">
              <span>{message}</span>
            </div>
          </div>
          <div className=" form-row mt-2 ">
            <div className="col-6 d-flex justify-content-center">
              <FileUploader handleFile={setImage} image={image} />
            </div>
            <div className="col-6  d-flex flex-column  ">
              <small>Acrónimo.titulo español*</small>
              <input
                type="text"
                className="form-control"
                placeholder="*Nombre español"
                value={nameEs}
                onChange={({ target: { value } }) => setNameEs(value)}
                disabled={!allowedEdit}
              />
              <small>Acrónimo.titulo inglés*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre inglés"
                maxLength="500"
                value={nameEn}
                onChange={({ target: { value } }) => setNameEn(value)}
                disabled={!allowedEdit}
              />
              <small>Acrónimo.titulo valenciano*</small>
              <input
                type="text"
                className="form-control"
                placeholder="Nombre valenciano"
                maxLength="500"
                value={nameVa}
                onChange={({ target: { value } }) => setNameVa(value)}
                disabled={!allowedEdit}
              />
            </div>
          </div>

          <div className="form-row mt-2">
            <div className="col">
              <span>Fecha de inicio</span>
              <DatePicker
                className="form-control input"
                placeholderText="*Inicio"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd MMMM, yyyy"
                isClearable={allowedEdit}
                locale="es"
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Fecha de finalización</span>
              <DatePicker
                className="form-control input"
                placeholderText="*Finalización"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd MMMM, yyyy"
                isClearable={allowedEdit}
                locale="es"
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Código</span>
              <input
                type="text"
                className="form-control"
                placeholder="*Código"
                value={code}
                onChange={({ target: { value } }) => setCode(value)}
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Proyecto destacado?</span>
              <select
                className="form-control input"
                value={selected}
                onChange={({ target: { value } }) => setSelected(value)}
              >
                <option value={1}>Destacado</option>
                <option value={0}>No Destacado</option>
              </select>
              <p className="fs-10">
                El proyecto se muestra o no en el perfil de investigador
              </p>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <span>Entidad finaciadora*</span>
              <input
                type="text"
                className="form-control"
                placeholder="*Organismo financiador"
                value={funding}
                onChange={({ target: { value } }) => setFunding(value)}
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Financ público?</span>
              <select
                className="form-control input"
                value={isFundingPublic}
                onChange={({ target: { value } }) => {
                  setIsFundingPublic(value);
                }}
              >
                <option value={0}>Financ. oculto</option>
                <option value={1}>Financ. público</option>
              </select>
            </div>
            <div className="col">
              <span>Presupuesto*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Presupuesto (escribir moneda)"
                value={budget}
                onChange={({ target: { value } }) => setBudget(value)}
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Presupuesto del instituto*</span>
              <input
                type="text"
                className="form-control"
                placeholder="Presupuesto (escribir moneda)"
                value={institutebudget}
                onChange={({ target: { value } }) => setInstituteBudget(value)}
                disabled={!allowedEdit}
              />
            </div>
            <div className="col">
              <span>Presupuesto público?</span>
              <select
                className="form-control input"
                value={isBudgetPublic}
                onChange={({ target: { value } }) => {
                  setIsBudgetPublic(value);
                }}
              >
                <option value={0}>Presupuesto oculto</option>
                <option value={1}>Presupuesto público</option>
              </select>
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <select
                className="form-control input"
                value={""}
                onChange={({ target: { value } }) =>
                  addInvestigator(value, "IP")
                }
                disabled={!allowedEdit}
              >
                <option value={""}>Investigadores principales</option>
                {ips?.map((i) => (
                  <option key={i.id} value={JSON.stringify(i)}>
                    {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                  </option>
                ))}
              </select>
              {chosenIps?.map((ip) => (
                <span
                  key={ip.id}
                  style={{ cursor: "pointer" }}
                  className="badge badge-info badge-blue-color mr-1 mt-3  "
                  onClick={() => removeInvestigator(ip, "IP")}
                >
                  {`${ip.name} ${ip.surname1} ${
                    ip.surname2 ? ip.surname2 : ""
                  }`}{" "}
                  X
                </span>
              ))}
            </div>
            <div className="col">
              <select
                className="form-control input"
                value={""}
                onChange={({ target: { value } }) =>
                  addInvestigator(value, "PARTICIPANT")
                }
                disabled={!allowedEdit}
              >
                <option value={""}>Investigadores participantes</option>
                {participants?.map((i) => (
                  <option key={i.id} value={JSON.stringify(i)}>
                    {`${i.surname1} ${i.surname2 ? i.surname2 : ""}, ${i.name}`}
                  </option>
                ))}
              </select>
              {chosenParticipants?.map((participant) => (
                <span
                  key={participant.id}
                  style={{ cursor: "pointer" }}
                  className="badge badge-info badge-blue-color mr-1 mt-3  "
                  onClick={() => removeInvestigator(participant, "PARTICIPANT")}
                >
                  {`${participant.name} ${participant.surname1} ${
                    participant.surname2 ? participant.surname2 : ""
                  }`}
                  X
                </span>
              ))}
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col-6">
              <small>Seleccionar Programa*</small>
              <select
                className="form-control input"
                value={chosenProgramId}
                onChange={({ target: { value } }) => {
                  setChosenProgramId(value);
                }}
              >
                <option>Programa</option>
                {programs?.map(({ id, nameEs }) => (
                  <option key={id} value={id}>
                    {nameEs}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6">
              <small>Proyecto público?</small>
              <select
                className="form-control input"
                value={isProjectPublic}
                onChange={({ target: { value } }) => {
                  setIsProjectPublic(value);
                }}
              >
                <option value={1}>Sí</option>
                <option value={0}>No</option>
              </select>
              <p className="fs-10">
                El proyecto se muestra o no en la web del instituto
              </p>
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Investigador principal externo (indicar filiación)"
                value={externalIp}
                onChange={({ target: { value } }) => setExternalIp(value)}
                disabled={!allowedEdit}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Participantes externos"
                value={externalParticipants}
                onChange={({ target: { value } }) =>
                  setExternalParticipants(value)
                }
                disabled={!allowedEdit}
              />
            </div>
          </div>
          {(isCreate || allowedEdit) && (
            <>
              <div className="form-row mt-4">
                <div className="col">
                  <small>Texto en español</small>
                  <CKEditor
                    initData={descriptionEs}
                    config={editorConfig}
                    onChange={({ editor }) =>
                      setDescriptionEs(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>

              <div className="form-row mt-4">
                <div className="col">
                  <small>Texto en inglés</small>
                  <CKEditor
                    initData={descriptionEn}
                    config={editorConfig}
                    onChange={({ editor }) =>
                      setDescriptionEn(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>
              <div className="form-row mt-4">
                <div className="col">
                  <small>Texto en valenciano</small>
                  <CKEditor
                    initData={descriptionVa}
                    config={editorConfig}
                    onChange={({ editor }) =>
                      setDescriptionVa(editor.getData())
                    }
                    readOnly={!allowedEdit}
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-row mt-4">
            <div className="col-2">
              <small>Descripción pública?</small>
              <select
                className="form-control input"
                value={isDescriptionPublic}
                onChange={({ target: { value } }) => {
                  setIsDescriptionPublic(value);
                }}
              >
                <option value={1}>Sí</option>
                <option value={0}>No</option>
              </select>
            </div>
          </div>

          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Enlace del proyecto (asegúrate de poner 'https://' o 'http://' y la web) Ejemplo: https://www.sombradoble.es"
                value={link}
                onChange={({ target: { value } }) => setLink(value)}
                disabled={!allowedEdit}
              />
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Enlace de la convocatoria (asegúrate de poner 'https://' o 'http://' y la web) Ejemplo: https://www.sombradoble.es"
                value={convocationLink}
                onChange={({ target: { value } }) => setConvocationLink(value)}
                disabled={!allowedEdit}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
