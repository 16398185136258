import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { baseURL } from "../../utils/constants";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import logo from '../../assets/images/pngLogo.png'


interface IProps {
  handleFile(file: any): void;
  document?: string;
}

export const PdfUploader = ({ handleFile, document }: IProps) => {
  const inputFile: any = useRef(null);

  const handleChange = (e: any) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded.size > 15 * 1024 * 1024) {
      Swal.fire({
        icon: "error",
        text: "Documento demasiado grande, asegúrese de subir una menor a 15mb",
      });
      return;
    }
    handleFile(fileUploaded);
  };

  return (
		<div className="c">
			<img
				className="pointer"
				onClick={() => inputFile.current?.click()}
				// style={{ width: imgData ? '75%' : '40%' }}
				src={logo}
				width={200}
				alt=""
			/>
			<input
				type="text"
				style={{ cursor: 'pointer' }}
				className="form-control"
				placeholder="Suba un documento asociado"
				onClick={() => inputFile.current?.click()}
			/>
			<input
				type="file"
				ref={inputFile}
				style={{ display: 'none' }}
				id="file"
				accept=".pdf,.doc,.docx,.xls,.ppt,.xlsx,.pptx,.odt,.zip,.rar"
				onChange={handleChange}
			/>
			{!!(typeof document === 'string') && (
				<a
					href={baseURL + document}
					download={baseURL + document}
					target="_blank"
					rel="noreferrer"
				>
					{document?.split('documents')[1]}
				</a>
			)}
			{!!(document && typeof document !== 'string') && (
				<div className="d-flex mt-1">
					<FontAwesomeIcon icon={faCheck} />
					<span className="ml-1">documento listo para subir</span>
				</div>
			)}
		</div>
	);
};
